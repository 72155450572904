import { gql } from '@apollo/client';

export const GET_CLIENT = gql`
  query GetClient {
    getClient {
      id
      gender
      email
      country_code
      email_verified
      enabled
      first_name
      last_name
      mobile_verified
      profile_picture
      phone_number
      rating
      share_code
      type
      business_id
      stripe_id
    }
  }
`;
