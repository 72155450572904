import fetchJson from '../../lib/fetchApi';
import { clearAuthToken, getAuthToken } from '../util/localStorage';

export const isAuthenticated = async () => {
  // Check if the user is authenticated (you can implement your own logic)
  // For example, you can check for the presence of a user token in localStorage
  const userToken = getAuthToken();
  let decoded = await decodeToken(userToken);

  if (decoded && decoded.role === 'guest') {
    console.log('O HIT');
    return false;
  } else {
    return !!userToken;
  }
};

export const logOut = async () => {
  const { error, data }: { error: boolean; data: string } = await fetchJson(
    `/api/logout`
  );
  if (!error) {
    // Log the user out (you can implement your own logout logic)
    // For example, you can remove the user token from localStorage
    clearAuthToken();
  }
};

export const decodeToken = async (token) => {
  if (token) {
    try {
      const { data }: { error: boolean; data: any } = await fetchJson(
        `/api/jwt`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: token,
          }),
        }
      );

      return data;
    } catch (e) {
      clearAuthToken();
      return null;
    }
  }
};
